<template>
  <div class="large-font">
    404 Sorry, the page you are looking for is not found
  </div>
</template>

<script>
export default {
    name:"PageNotFound",
}
</script>

<style>
.large-font{
    font-size:75px;
    font-weight: bold;
}
</style>