import Vue from "vue";
import Vuex from "vuex";
import {db, getScheduleRaces, auth} from "../firebase";
import { collection, getDocs, getDoc, writeBatch, doc, query, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import VuexPersistence from "vuex-persist"

Vue.use(Vuex);

// save user in session storage
const vuexSessionStorage = new VuexPersistence({
  key: "store",
  storage: window.sessionsStorage,
  reducer: (state) =>({
    user: state.user,
    scheduleName: state.scheduleName,
  })
})

const store = new Vuex.Store({
  plugins:[vuexSessionStorage.plugin],
  state: {
    // User ID (this eventually needs to be made persistent via session storage)
    user: "",
    scheduleName: "",
    allScheduleNames: [],

    // Schedule
    racers: [],
    racersMap: new Map(),
    schedule: [],

    //Scorers
    Scorers: {
      schedule: [],
      currentRaceId: null,
      currentRaceRacers: [],
      currentRaceScores: [],
    },
    ScoreBoard:{
      scoreBoard:[],
    },
  },
  getters:
  {
    isLoggedIn(state){
      return state.user !== ""
    },
  },
  mutations: {
    lootStore(state){
      state.user="";
      state.scheduleName="",
      state.allScheduleNames=[];
      state.racers=[];
      state.racersMap=new Map();
      state.schedule=[];
      state.Scorers ={
        schedule:[],
        currentRaceId:[],
        currentRaceRacers:[],
        currentRaceScores:[],
      };
      state.ScoreBoard={
        scoreBoard:[],
      }
    },
    doNothing(){
      console.log("This needs to be cleaned up. Do nothing mutation in store...")
    },
    updateRacers(state, {racersArr, racersMap}){
      state.racers = racersArr;
      state.racersMap = racersMap;
    },
    // Scorers
    updateSchedule(state, { schedule}) {
      state.Scorers.schedule = schedule;
    },
    updateCurrentRace(
      state,
      { currentRaceId, currentRaceRacers, currentRaceScores }
    ) {
      state.Scorers.currentRaceId = currentRaceId;
      state.Scorers.currentRaceRacers = currentRaceRacers;
      state.Scorers.currentRaceScores = currentRaceScores;
    },
    //Score Board
    updateScoreBoard(state, {scoreBoard}){
      state.ScoreBoard.scoreBoard=scoreBoard;
    },
    updateUser(state,{user}){
      state.user=user;
    },
    updateAllScheduleNames(state,{names}){
      state.allScheduleNames=names;
    },
    updateScheduleName(state, {name}){
      state.scheduleName=name;
    },
  },
  actions: {
    async updateAllRacers({commit}, {userId, scheduleName, newRacerList, removedRacers}){
      // create batch to update racer if already in collection
      // also add racer if not already in db
      const batch = writeBatch(db);
      for(let i = 0; i < newRacerList.length; i++){
          if(newRacerList[i].dbId === undefined){
              // gets autogenerated ref
              let myNewRef = doc(collection(db, "Users", userId, "MasterSchedules", scheduleName, "Racers"));
              batch.set(myNewRef, { name: newRacerList[i].name, id: newRacerList[i].id, score: 0 });
          }
          else{
              const myRef = doc(db, "Users",userId,"MasterSchedules", scheduleName, "Racers", newRacerList[i].dbId);
              batch.update(myRef, { name: newRacerList[i].name, id: newRacerList[i].id, score: 0 });
          }
      }
      for(let i = 0; i < removedRacers.length; i++){
          if(removedRacers[i].dbId === undefined) continue;

          let myRemRef = doc(db, "Users",userId,"MasterSchedules", scheduleName, "Racers", removedRacers[i].dbId);
          batch.delete(myRemRef);
      }
      
      await batch.commit();

      commit("doNothing");
    },
    async getAllRacers({commit}, {userId, scheduleName}){
      if(!scheduleName){
        console.log("Get all racers was called with no schedule name");
        return;
      }
      const query = await getDocs(collection(db, "Users", userId, "MasterSchedules", scheduleName, "Racers"));
      let allRacers = [];
      let allRacersMap = new Map();
      query.forEach((doc)=> {
          let racer = doc.data();
          allRacersMap.set(doc.id, racer);
          racer.dbId = doc.id;
          allRacers.push(racer);
      });
      commit("updateRacers",{
        racersArr: allRacers,
        racersMap: allRacersMap,
      });
    },
    //Scorers
    async getFullSchedule({commit}, {userId, scheduleId}) {
      let mySchedule = await getScheduleRaces(userId, scheduleId);
      commit("updateSchedule", { schedule: mySchedule });
      
    },
    async subscribeToScoreBoard({commit}, {userId, scheduleName}){
      // Make sure all racers is up to date
      const myQuery = query(collection(db,"Users", userId, "MasterSchedules",scheduleName,"Racers"));
      let myScoreBoard=[];
      return onSnapshot(myQuery,(snapshot) =>
      {
        myScoreBoard= [];
        snapshot.forEach((doc) =>{
          myScoreBoard.push({racerNumber: doc.data().id, racerName: doc.data().name, racerScore: doc.data().score, numberOfRaces: "NA"})
        });
        let sortedScoreBoard = myScoreBoard.sort((a,b) => b.racerScore-a.racerScore);
        commit("updateScoreBoard", { scoreBoard: sortedScoreBoard });
      });
    },

    async subscribeToUser({commit}){
      onAuthStateChanged(auth, (user) => {
        if (user) {
          commit("updateUser",{user: user.uid});
          console.log("Auth State Changed", user.uid);
        } else {
          commit("updateUser",{user:""});
          console.log("Auth State Changed. No user");
        }
        
      });
      
    },
    async getAllScheduleNames({state}){
      console.log("Getting Names",state.user);
      if(state.user !== ""){
        let names = (await getDoc(doc(db,"Users",state.user))).data();
        this.commit("updateAllScheduleNames",{names: names.Names});
        console.log("success", state.allScheduleNames)
      }
      
    }
  },
});

export default store;
